.lex-web-ui-iframe {
  bottom: 1.5rem;
  display: none; /* hidden by default changed once iframe is loaded */
  margin-bottom: 0px;
  margin-left: 2px;
  margin-right: 3vw;
  margin-top: 2px;
  max-width: 66vw;
  height: 80vh; /* dynamically changed on iframe maximize/minimize */
  min-width: calc(50vw - 3vw); /* half viewport width minus margin right */
  position: fixed;
  right: 0;
  z-index: 2147483637; /* max z-index (2147483647) - 10 */
}

.lex-web-ui-iframe iframe {
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.lex-web-ui-iframe--show {
  display: flex;
}

.lex-web-ui-iframe--minimize {
  max-width: 85px !important;
  max-height: 85px !important;
  border-radius: 85px !important;
}

/* disable box shadow when minimized */
.lex-web-ui-iframe.lex-web-ui-iframe--minimize iframe {
  box-shadow: none;
  border-radius: none;
}

/* hide on very small resolutions */
@media only screen and (max-width: 240px),
only screen and (max-height: 256px)
{
  .lex-web-ui-iframe {
    display: none!important;
  }

  .lex-web-ui-iframe--minimize {
    max-width: 85px !important;
    max-height: 85px !important;
  }
}
/* take most space on small resolutions (smart phones) */
@media only screen
and (min-width: 241px)
and (max-width: 480px) {
  .lex-web-ui-iframe {
    min-width: 96vw;
    height: 84vh;
    margin-right: 2vw;
    align-self: center;
  }

  .lex-web-ui-iframe--minimize {
    max-width: 85px !important;
    max-height: 85px !important;
    border-radius: 85px !important;
  }

}

/* adjust down on medium resolutions */
@media only screen
and (min-width: 481px)
and (max-width: 960px) {
  .lex-web-ui-iframe {
    min-width: 90vw;
  }

  .lex-web-ui-iframe.lex-web-ui-iframe--show.lex-web-ui-iframe--minimize {
    max-width: 85px !important;
    max-height: 85px !important;
    border-radius: 85px !important;
    min-width: 85px !important;
  }
}

.lex-web-ui-iframe iframe {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
